// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* -------------------------------------------- */
/* Reset                                        */
/* https://piccalil.li/blog/a-modern-css-reset/ */
/* Other ways: Hard reset- Eric Meyer,          */
/*             normalize, sanitize              */
/* -------------------------------------------- */

/* Box sizing */
*,
*::before,
*::after {
  /* Border-box helps to include padding,border to width property */
  box-sizing: border-box;
}

/* Reset margins */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
picture {
  /* Helps to avoid collapsing margins and reintroduce our own margins */
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5; /* Most browsers have 1.4 which is small */
}

/* Images */
img,
picture {
  max-width: 100%; /* Responsive images */
  display: block; /* Avoid gaps below image */
}

/* Forms elements */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove animations if possible */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01 !important;
    scroll-behavior: auto !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,iDAAiD;AACjD,iDAAiD;AACjD,iDAAiD;AACjD,iDAAiD;AACjD,iDAAiD;AACjD,iDAAiD;;AAEjD,eAAe;AACf;;;EAGE,iEAAiE;EACjE,sBAAsB;AACxB;;AAEA,kBAAkB;AAClB;;;;;;;;;;EAUE,sEAAsE;EACtE,SAAS;AACX;;AAEA,2BAA2B;AAC3B;EACE,iBAAiB;EACjB,gBAAgB,EAAE,0CAA0C;AAC9D;;AAEA,WAAW;AACX;;EAEE,eAAe,EAAE,sBAAsB;EACvC,cAAc,EAAE,2BAA2B;AAC7C;;AAEA,mBAAmB;AACnB;;;;EAIE,aAAa;AACf;;AAEA,kCAAkC;AAClC;EACE;;;IAGE,qCAAqC;IACrC,uCAAuC;IACvC,oCAAoC;IACpC,gCAAgC;EAClC;AACF","sourcesContent":["/* -------------------------------------------- */\n/* Reset                                        */\n/* https://piccalil.li/blog/a-modern-css-reset/ */\n/* Other ways: Hard reset- Eric Meyer,          */\n/*             normalize, sanitize              */\n/* -------------------------------------------- */\n\n/* Box sizing */\n*,\n*::before,\n*::after {\n  /* Border-box helps to include padding,border to width property */\n  box-sizing: border-box;\n}\n\n/* Reset margins */\nbody,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\nfigure,\npicture {\n  /* Helps to avoid collapsing margins and reintroduce our own margins */\n  margin: 0;\n}\n\n/* Set core body defaults */\nbody {\n  min-height: 100vh;\n  line-height: 1.5; /* Most browsers have 1.4 which is small */\n}\n\n/* Images */\nimg,\npicture {\n  max-width: 100%; /* Responsive images */\n  display: block; /* Avoid gaps below image */\n}\n\n/* Forms elements */\ninput,\nbutton,\ntextarea,\nselect {\n  font: inherit;\n}\n\n/* Remove animations if possible */\n@media (prefers-reduced-motion: reduce) {\n  *,\n  *::before,\n  *::after {\n    animation-duration: 0.01ms !important;\n    animation-iteration-count: 1 !important;\n    transition-duration: 0.01 !important;\n    scroll-behavior: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
