import { createContext, useContext, useState } from "react";
import { WindowTypesCategories, WindowTypes } from "../constants/ModalTypes";

type ModalContextProps = {
  visibilityState: Record<WindowTypesCategories, boolean>;
  openPopup: (popupId: WindowTypesCategories) => void;
  closePopup: (popupId: WindowTypesCategories) => void;
};

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

//Initialize all modal windows as closed
const INITIAL_VALUE: Record<WindowTypesCategories, boolean> = Object.keys(
  WindowTypes
).reduce((acc, window) => {
  const windowType = window as WindowTypesCategories;
  acc[windowType] = false;
  return acc;
}, {} as Record<WindowTypesCategories, boolean>);

// Modal Provider component to manage modal state
const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [visibilityState, setVisibilityState] = useState(INITIAL_VALUE);

  const openPopup = (popupId: WindowTypesCategories) => {
    setVisibilityState((prevState) => ({
      ...prevState,
      [popupId]: true,
    }));
  };

  const closePopup = (popupId: WindowTypesCategories) => {
    setVisibilityState((prevState) => ({
      ...prevState,
      [popupId]: false,
    }));
  };

  return (
    <ModalContext.Provider
      value={{
        visibilityState,
        openPopup,
        closePopup,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { useModal, ModalProvider };
