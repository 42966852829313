import { Athlete } from "./Athlete.types";

export enum Roles {
  ADMIN = "admin",
  ORGANIZER = "organizer",
  USER = "user",
}

export enum ChampionshipStatus {
  INIT = "init",
  RUNNING = "running",
  OVERVIEW = "overview",
  FINISHED = "finished",
}

export interface UserLoginInput {
  email: string;
  password: string;
}

export interface UserLoggedIn {
  id: string;
  name: string;
  championship_id: string;
  role: Roles;
  championship_status: ChampionshipStatus;
}

export interface UserFromBackend {
  success: boolean;
  message: string;
  response: Athlete[];
}

export type ChildrenProps = {
  children: React.ReactNode;
};
