import AppBar from "@mui/material/AppBar";
import { useCookies } from "react-cookie";
import { Toolbar, MenuItem, Typography } from "@mui/material";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useAuth } from "../../contexts/AuthContext";

export const ResponsiveAppBar = () => {
  const { dictionary } = useLocalization();
  const { user } = useAuth();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);

  const logout = () => {
    removeCookie("access_token");
    window.location.reload();
  };

  return (
    <AppBar position="sticky" sx={{ zIndex: 1400 }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Typography variant="h6">Taekwondo-Games</Typography>
        {user.id !== "" ? (
          <>
            <MenuItem onClick={logout}>
              <Typography textAlign="center" variant="h6">
                {dictionary.logout}
              </Typography>
            </MenuItem>
          </>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;
