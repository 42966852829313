import React, { createContext, useContext } from "react";
import { ToastContainer, toast, ToastOptions, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultOptions: ToastOptions = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

const ToastContext = createContext(toast);

interface Props {
  children: React.ReactNode;
}

export const ToastProvider = ({ children }: Props) => {
  return (
    <ToastContext.Provider value={toast}>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        transition={Slide}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const showToast = useContext(ToastContext);

  const showSuccess = (message: string, options?: ToastOptions) => {
    const finalOptions = options || defaultOptions;

    showToast.success(message, finalOptions);
  };

  const showError = (message: string, options?: ToastOptions) => {
    const finalOptions = options || defaultOptions;

    showToast.error(message, finalOptions);
  };

  const showInfo = (message: string, options?: ToastOptions) => {
    const finalOptions = options || defaultOptions;

    showToast.info(message, finalOptions);
  };

  const showWarning = (message: string, options?: ToastOptions) => {
    const finalOptions = options || defaultOptions;

    showToast.warning(message, finalOptions);
  };

  return { showSuccess, showError, showInfo, showWarning };
};
