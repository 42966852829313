import { useAuth } from "../../contexts/AuthContext";
import { useCookies } from "react-cookie";
import jwtDecode from "jwt-decode";
import { Suspense, lazy, useEffect } from "react";
import { Roles, UserLoggedIn } from "../../types/Auth.types";

const LoginPage = lazy(() => import("../loginPage/LoginPage"));
const AdminPage = lazy(() => import("../adminPage/AdminPage"));
const OrganizerPage = lazy(() => import("../organizerPage/OrganizerPage"));
const UserPage = lazy(() => import("../userPage/UserPage"));

const ProtectedRoute = () => {
  const { user, setUser } = useAuth();
  const [cookies] = useCookies(["access_token"]);

  useEffect(() => {
    const accessToken = cookies?.access_token;

    if (accessToken) {
      const returnedUser = jwtDecode<UserLoggedIn>(accessToken);
      setUser(returnedUser);
    }
  }, []);

  return (
    <>
      {user.id === "" ? (
        <Suspense fallback={<div>Loading...</div>}>
          <LoginPage />
        </Suspense>
      ) : user.role === Roles.ADMIN ? (
        <Suspense fallback={<div>Loading...</div>}>
          <AdminPage />
        </Suspense>
      ) : user.role === Roles.ORGANIZER ? (
        <Suspense fallback={<div>Loading...</div>}>
          <OrganizerPage />
        </Suspense>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <UserPage />
        </Suspense>
      )}
    </>
  );
};

export default ProtectedRoute;
