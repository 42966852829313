import gr from "./gr.json";

export type AvailableLanguages = "gr"; // | "en";

const dictionaries: Record<AvailableLanguages, Record<string, string>> = { gr };

export const getDictionary = (language: AvailableLanguages) => {
  return dictionaries[language];
};

// export const languageOptions = {
//   gr: 'Ελληνικά',
// };
