export const WindowTypes = {
  CREATE_ATHLETE: "create-athlete",
  EDIT_ATHLETE: (athleteId: string) => `edit-athlete-${athleteId}`,
  DELETE_ATHLETE: (athleteId: string) => `delete-athlete-${athleteId}`,

  CREATE_CLUB: "create-club",
  EDIT_CLUB: (clubId: string) => `edit-club-${clubId}`,
  DELETE_CLUB: (clubId: string) => `delete-club-${clubId}`,

  CREATE_AGE: "create-age",
  EDIT_AGE: (ageId: string) => `edit-age-${ageId}`,
  DELETE_AGE: (ageId: string) => `delete-age-${ageId}`,

  CREATE_WEIGHT: "create-weight",
  EDIT_WEIGHT: (weightId: string) => `edit-weight-${weightId}`,
  DELETE_WEIGHT: (weightId: string) => `delete-weight-${weightId}`,

  EDIT_ATHLETES_OF_OTHER_CLUB: (clubId: string) =>
    `edit-athletes-of-other-club-${clubId}`,

  CHANGE_OPPONENTS: (athleteId: string) => `change-opponents-${athleteId}`,

  UNSELECT_MATCH: (matchId: string) => `unselect-match-${matchId}`,
  SELECT_MATCH: (matchId: string) => `select-match-${matchId}`,

  INSERT_ALONE: (athleteId: string) => `insert-alone-${athleteId}`,
};

export type WindowTypesCategories = string;
