import { createContext, useState, useContext } from "react";
import {
  ChampionshipStatus,
  ChildrenProps,
  Roles,
  UserLoggedIn,
} from "../types/Auth.types";

type AuthContextValue = {
  user: UserLoggedIn;
  setUser: React.Dispatch<React.SetStateAction<UserLoggedIn>>;
  setChampionshipStatus: (status: ChampionshipStatus) => void;
};

const INITIAL_STATE: UserLoggedIn = {
  id: "",
  name: "",
  championship_id: "",
  role: Roles.USER,
  championship_status: ChampionshipStatus.INIT,
};

const AuthContext = createContext<AuthContextValue | null>(null);

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

const AuthContextProvider = ({ children }: ChildrenProps) => {
  const [user, setUser] = useState<UserLoggedIn>(INITIAL_STATE);

  const setChampionshipStatus = (status: ChampionshipStatus) => {
    setUser((prevState) => ({
      ...prevState,
      championship_status: status,
    }));
  };

  return (
    <AuthContext.Provider value={{ setUser, user, setChampionshipStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export { useAuth, AuthContextProvider };
