import App from "./App";
import { createRoot } from "react-dom/client";
import "./index.css";
import { QueryClientProvider, QueryClient } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import { AuthContextProvider } from "./contexts/AuthContext";
import { LocalizeProvider } from "./contexts/LocalizationContext";
import { CookiesProvider } from "react-cookie";
import { ModalProvider } from "./components/common/withModal/context/BasicModalContext";
import { ToastProvider } from "./components/common/toast/Toast";

const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root")!);
root.render(
  <CookiesProvider>
    <LocalizeProvider>
      <ToastProvider>
        <ModalProvider>
          <QueryClientProvider client={queryClient}>
            <AuthContextProvider>
              <App />
            </AuthContextProvider>
            {/*TODO delete this in production */}
            {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
          </QueryClientProvider>
        </ModalProvider>
      </ToastProvider>
    </LocalizeProvider>
  </CookiesProvider>
);
