import { getDictionary, AvailableLanguages } from "../languages";
import { createContext, useState, useContext } from "react";

interface LanguageContextType {
  userLanguage: string;
  dictionary: Record<string, string>;
}

// create the language context with default selected language
const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

const useLocalization = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLocalization must be used within an LocaleProvider");
  }
  return context;
};

const LocalizeProvider = ({ children }: { children: React.ReactNode }) => {
  const [userLanguage] = useState<AvailableLanguages>("gr");
  const dictionary: Record<string, string> = getDictionary(userLanguage);

  const provider: LanguageContextType = {
    userLanguage,
    dictionary,
  };
  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

export { useLocalization, LocalizeProvider };
